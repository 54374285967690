import * as React from "react";
import { Typography, Card, CardContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
var currencyFormatter = require('currency-formatter');
export interface IAddressViewProps {
  address: string;
  balance: string;
  txCount: number;
  code: string;
}

function AddressView(props: IAddressViewProps) {
  const { address, balance, txCount, code } = props;
  const { t } = useTranslation();
  var rate= 0.000001;

  var qoin = currencyFormatter.format(balance, {
    symbol: 'Qoin',
    decimal: '.',
    thousand: ',',
    precision:4,
    format: '%s%f' // %s is the symbol and %v is the value
  });
 
  var toltal= currencyFormatter.format(Number(rate)*Number(balance), {
     code:'USD'})

  var Price = currencyFormatter.format(rate, {
    symbol: '$',
    decimal: '.',
    thousand: ',',
    precision:6,
    format: '%s%v' // %s is the symbol and %v is the value
  });
  // console.log("balance.toLocaleString():",balance)
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{t("Address")}: {address}</Typography>
        <Typography variant="h6">{t("Balance")}: {qoin}</Typography>
        <Typography variant="h6">{t("Price")}:{Price}</Typography>
        <Typography variant="h6">{t("Total")}:{toltal}</Typography>
        <Typography variant="h6">{t("Transactions")}: {txCount}</Typography>
        <br />
        <div>
          <div>{t("Code")}</div>
          <pre>
            <code>{code}</code>
          </pre>
        </div>
      </CardContent>
    </Card>
  );
}

export default AddressView;
